import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { PublicClientApplication } from "@azure/msal-browser";
import Error from '../components/Error';
import Delivery from '../assets/images/delivery.jpg';
import FoodConceptLogo from '../assets/images/FoodConcepts_Logo-1 1.png';

const SignIn = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    useEffect(()=>{
        console.log(`${process.env.REACT_APP_ENDPOINT_URL}/egrn/v1/grn`)
    })
    const msalConfig = {
        auth: {
            clientId: process.env.REACT_APP_CLIENT_ID,
            authority: process.env.REACT_APP_AUTHORITY,
            redirectUri: process.env.REACT_APP_REDIRECT_URI,
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
        },
    };

    const handleLogin = () => {
        if (error) return;

        const msalInstance = new PublicClientApplication(msalConfig);
        const loginRequest = {
            scopes: [process.env.REACT_APP_SCOPE],
        };

        msalInstance.initialize()
            .then(() => {
                const currentAccounts = msalInstance.getAllAccounts();

                if (!currentAccounts || currentAccounts.length < 1) {
                    msalInstance.loginPopup(loginRequest)
                        .then((data) => {
                            console.log(data);
                            const currentAccounts = msalInstance.getAllAccounts();
                            const username = currentAccounts[0].username;

                            localStorage.setItem("account", JSON.stringify(data.account));
                            localStorage.setItem("accessToken", data.accessToken);
                            localStorage.setItem("idToken", data.account.idToken);
                            localStorage.setItem("name", data.account.name);
                            localStorage.setItem("username", data.account.username);
                            localStorage.setItem('isLoggedin', true);
                            localStorage.setItem('msalConfig', JSON.stringify(msalConfig));
                            toast.success('Login successful');
                            navigate('/home');
                        })
                        .catch(error => {
                            console.error("Login Error: " + error);
                        });
                } else if (currentAccounts.length > 1) {
                    console.warn("Multiple accounts detected.");
                } else if (currentAccounts.length === 1) {
                    const username = currentAccounts[0].username;
                    localStorage.setItem("username", username);
                    navigate('/home');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className='h-screen'>
            <div className='bg-white h-16 flex items-center w-full'>
                <div className='flex items-center py-4 px-8'>
                    <img src={FoodConceptLogo} alt="" className='h-16 pr-2 ' />
                    <h1 className="hidden md:flex text-lg text-center pl-2 border-l-2 py-2 border-gray-400 font-medium text-blue-900">
                        Goods Receipt Management Portal
                    </h1>
                </div>
            </div>
            <div className="relative h-full bg-cover bg-center" style={{ backgroundImage: `url(${Delivery})` }}>
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="flex flex-col w-[85%] md:[40%] text-center justify-center items-center">
                        <p className="text-4xl font-bold text-white mb-3">Simplified Goods Delivery Management</p>
                        <p className="text-base font-normal text-white mb-3">Manage goods delivery with ease, ensuring accuracy and transparency</p>
                        <button
                            className="bg-blue-700 hover:bg-blue-800 text-white font-semibold mt-8 py-3 px-6 rounded-lg"
                            type="button"
                            aria-disabled={false}
                            onClick={handleLogin}
                        >
                            Get Started
                        </button>
                    </div>
                </div>
                {error && <Error message={errorMessage} show={showModal} handleShow={() => setShowModal(false)} />}
            </div>
        </div>
    );
};

export default SignIn;
