import React from 'react'
import sessionExpired from '../assets/images/session expired.png'
import { useNavigate } from 'react-router-dom'

const SessionExpired = () => {
  const navigate = useNavigate();

  const handleRefreshToken = () => {
    localStorage.clear(); // Clear localStorage
    navigate('/'); // Redirect to homepage
  }

  return (
    <section className="bg-white min-h-screen flex items-center">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center flex flex-col justify-center items-center">
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
            Session Expired
          </p>
          <img src={sessionExpired} alt="Session Expired" className="w-24 h-24 object-cover" />
          <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
            Click the button below to refresh
          </p>
          <button
            className="inline-flex text-white bg-blue-600 hover:bg-primary-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900"
            onClick={handleRefreshToken}
          >
            Back to Homepage
          </button>
        </div>
      </div>
    </section>
  )
}

export default SessionExpired;
