import React from 'react'

const PageTitle = ({pagetitle}) => {
  return (
    <div className='flex page-title box-shadow items-center'>
      <div className="blue-text text-lg px-4">{pagetitle}</div>
    </div>
  )
}

export default PageTitle
