import React from 'react';
import { ReactComponent as Vector } from '../assets/images/Vector.svg';
import { ReactComponent as Vector1 } from '../assets/images/Vector (1).svg';
import { ReactComponent as Vector2 } from '../assets/images/Vector (2).svg';
import { useLocation, Link } from 'react-router-dom';
import Logo from '../assets/images/FoodConcepts_Logo-1 1.png';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Logout from '@mui/icons-material/Logout';
import { PublicClientApplication } from "@azure/msal-browser";
const Sidebar = () => {
  const location = useLocation();
  console.log(location.pathname)
  const [confirmLogout, setConfirmLogout] = React.useState(false);
  const userName = localStorage.getItem("username");
  const msalConfig = JSON.parse(localStorage.getItem("msalConfig"));
  const logoutHandler = () => {
    const msalInstance = new PublicClientApplication(msalConfig);
    msalInstance.initialize()
        .then(()=>{
            const logoutRequest = {
                account: msalInstance.getAccountByUsername(userName),
                //mainWindowRedirectUri: 'http://localhost:3000/approval',
                //  mainWindowRedirectUri: 'https://fcgrn.wajesmarthrms.website/',
                 mainWindowRedirectUri: 'https://crstoreportal.foodconceptsplc.com',
            }

            // localStorage.setItem('isLoggedin', false);
           
            msalInstance.logoutPopup(logoutRequest);
            setConfirmLogout(false)
            localStorage.clear()
        })
        .catch((error)=>{
            console.log(error)
        })
  };

  return (
    <ul className="text-base font-medium sidebar shadow h-screen flex flex-col">
       {confirmLogout && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center w-screen h-screen z-50">
          <div className="p-4 sm:p-10 bg-gray-50 rounded-md w-[300px] md:w-[500px] text-center">
            <span className="mb-4 inline-flex justify-center items-center w-[62px] h-[62px] rounded-full border-4 border-yellow-50 bg-yellow-100 text-yellow-500">
              <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
              </svg>
            </span>

            <h3 className="mb-2 text-2xl font-bold text-gray-800">Log out</h3>
            <p className="text-gray-500">Are you sure you would like to log out of your account?</p>

            <div className="mt-6 flex justify-center gap-x-4">
            <button
                className="py-2.5 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-white cursor-pointer text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm"
                onClick={() => setConfirmLogout(false)}
              >
                Cancel
              </button>
              <button
                className="py-2.5 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-blue-500  shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm text-white"
                onClick={logoutHandler}
              >
                Proceed
              </button>
            
            </div>
          </div>
        </div>
      )}

                 <img src={Logo} className="md:px-3 md:my-[1.2rem]" alt="" /> 

      <li className={`sidebar-text text-sm  mt-3 py-2    ${location.pathname === '/home' ? 'active' : location.pathname === '/grn' ? 'active' : ''}`}>
        <Link className='flex gap-1 items-center' to='/home'>
          <Vector className={`mx-2 icon   ${location.pathname === '/home' ? 'active' : location.pathname === '/grn' ? 'active' : ''}`} />
          <span>Create GRN</span>
        </Link>
      </li>
      <li className={`sidebar-text mt-3 text-sm py-2 ${location.pathname === '/history' ? 'active': ''}`}>
  <Link className='flex gap-1 items-center' to='/history'>
    <Vector1 className={`mx-2 icon ${location.pathname === '/history' ? 'active': ''}`} />
    <span>GRN History</span>
  </Link>
</li>
     <div className="mt-auto mb-2 py-2  text-blue-800">
        <MenuItem onClick={()=>{setConfirmLogout(true)}} className="text-blue-800 px-3">
          <ListItemIcon>
            <Logout fontSize="small" className="text-blue-800" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </div>
    </ul>
  );
};

export default Sidebar;